import React from 'react';

import { useReactiveVar } from '@apollo/client';
import styled from '@emotion/styled';
import { Drawer, Row, Col, Divider } from 'antd';
// import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
// import { RiFeedbackLine } from 'react-icons/ri';
import Avatar from 'react-avatar';

import LogoutButtonIcon from '@assets/Icons/LogoutButton.svg';
import LogoutIcon from '@assets/Icons/LogoutIcon.svg';
// import SupportIcon from '@assets/Icons/Support.svg';
// import FeedbackModal from '@components/modules/FeedbackModal';
import LogoutModal from '@components/modules/LogoutModal';
import { userVar } from '@global/apollo/reactiveVars';
// import { KOIREADER_SUPPORT_EMAIL } from 'src/global/constants';

interface Props {
  className?: string;
  children?: JSX.Element;
  profileDrawerClose(): void;
  isProfileDrawerOpen: boolean;
}

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    // height: calc(100vh - 5em) !important;
    bottom: 0;
    min-width: 22.5em;
  }
  .ant-drawer-body {
    /* background-color: ${(props) => props.theme.profilePanel.bgColor}; */
    padding: 0;
  }
  .menuitem {
    margin-bottom: 0;
    padding: 0.75em 0 0.75em 0.5em;
    // height: 5.25em;
    cursor: pointer;
  }
  .menuitem:hover {
    background-color: var(--ant-color-bg-spotlight);
  }
  .menuitem:active {
    background-color: var(--ant-color-bg-spotlight);
  }
  .menuitem-info {
    padding-left: 0.7em;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .menuitem-heading {
    font-size: 1.25em;
    /* color: ${(props) => props.theme.profilePanel.textHeading}; */
  }
  .menuitem-desc {
    font-size: 1em;
    color: ${(props) => props.theme.profilePanel.textDesc};
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    color: ${(props) => props.theme.profilePanel.iconColor};
    fill: ${(props) => props.theme.profilePanel.iconColor};
    width: 3.5rem;
    height: 3.5rem;
  }
  .logout-icon {
  }
  .avatar-divider {
    margin: 0;
    /* background-color: ${(props) => props.theme.profilePanel.dividerColor}; */
    width: 100%;
  }
  .user {
    margin: 0.5em 0;
  }
`;

const ProfileDrawer = (props: Props): JSX.Element => {
  // Get User
  const user = useReactiveVar(userVar);

  // const theme = useTheme();
  const { t: common } = useTranslation('common');

  // feedback modal
  // const [isFeedbackModalVisible, setIsFeedbackModalVisible] =
  //   React.useState(false);
  // const showFeedbackModal = () => {
  //   setIsFeedbackModalVisible(true);
  // };
  // const feedbackModalClose = () => {
  //   setIsFeedbackModalVisible(false);
  // };

  // logout modal
  const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);
  const showLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };
  const logoutModalClose = () => {
    setIsLogoutModalOpen(false);
  };
  // get current loggedIn user
  // console.log('users', user);
  return (
    <div className={props.className}>
      <StyledDrawer
        placement="right"
        closable={false}
        onClose={props.profileDrawerClose}
        open={props.isProfileDrawerOpen}
      >
        <div className="user">
          <Row className="menuitem">
            <Col span={6} className="icon-container">
              <Avatar
                name={user?.name}
                size="4em"
                textSizeRatio={2.2}
                round={true}
                maxInitials={2}
                onClick={() => console.log('User Avatar clicked!')}
              />
            </Col>
            <Col span={18}>
              <div className="menuitem-info">
                <div className="menuitem-heading">{user?.name}</div>
                {/* <div className="menuitem-desc menuitem-email">
                  Emma.birch@koireader.com
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
        <Divider className="avatar-divider" />
        {/* <div>
          <Row
            align="middle"
            justify="space-between"
            className="menuitem"
            onClick={() => {
              props.profileDrawerClose();
              showFeedbackModal();
              console.log('show feedback modal');
            }}
          >
            <Col span={6} className="icon-container">
              <RiFeedbackLine className="icon" />
            </Col>
            <Col span={18}>
              <div className="menuitem-info">
                <div className="menuitem-heading">
                  {common('profileDrawer.giveFeedback')}
                </div>
                <div className="menuitem-desc">
                  {common('profileDrawer.helpUsImproveOurServices')}
                </div>
              </div>
            </Col>
          </Row>
        </div> */}
        {/* <div> */}
        {/*   <Row */}
        {/*     align="middle" */}
        {/*     justify="space-between" */}
        {/*     className="menuitem" */}
        {/*     onClick={() => { */}
        {/*       window.location.href = `mailto:${KOIREADER_SUPPORT_EMAIL}`; */}
        {/*     }} */}
        {/*   > */}
        {/*     <Col span={6} className="icon-container"> */}
        {/*       <SupportIcon className="icon" /> */}
        {/*     </Col> */}
        {/*     <Col span={18}> */}
        {/*       <div className="menuitem-info"> */}
        {/*         <div className="menuitem-heading"> */}
        {/*           {common('profileDrawer.helpAndSupport')} */}
        {/*         </div> */}
        {/*       </div> */}
        {/*     </Col> */}
        {/*   </Row> */}
        {/* </div> */}
        <div>
          <Row
            align="middle"
            justify="space-between"
            className="menuitem"
            onClick={() => {
              props.profileDrawerClose();
              showLogoutModal();
            }}
          >
            <Col span={6} className="icon-container">
              <LogoutButtonIcon className="icon logout-icon" />
            </Col>
            <Col span={18}>
              <div className="menuitem-info">
                <div className="menuitem-heading">
                  {common('profileDrawer.logout')}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* <FeedbackModal
          isOpen={isFeedbackModalVisible}
          closeModal={feedbackModalClose}
        /> */}
        <LogoutModal
          icon={<LogoutIcon />}
          isOpen={isLogoutModalOpen}
          closeModal={logoutModalClose}
        />
      </StyledDrawer>
    </div>
  );
};

export default ProfileDrawer;
