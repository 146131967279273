import React from 'react';

import styled from '@emotion/styled';
import Link from 'next/link';

import ArrowRight from '@assets/Icons/ArrowRight.svg';
interface Breadcrumb {
  icon?: JSX.Element;
  title?: string;
}
interface PageHeaderProps {
  icon?: any;
  title?: string;
  buttons?: JSX.Element[];
  breadcrumb?: Breadcrumb;
  titleLink?: string;
  className?: string;
}

const Header = styled.div`
  width: 100%;
  height: 62px;
  padding: 0.8em 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--ant-color-bg-container) !important;
  border-bottom: 2px solid var(--ant-color-border) !important;
`;

const SideHeading = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-right: 10px;
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.gray50};
  margin-left: 0.2em;
  font-size: 1em;
  p {
    cursor: auto;
  }
  svg {
    font-size: 0.5em;
    // margin-right: 0.5em;
    // margin-top: 0.1em;
    fill: var(--ant-color-primary);
  }
`;

const BreadcrumbIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 1em; /* Customize the size of the breadcrumb icon here */
    margin-right: 0.5em;
  }
`;

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const Heading = styled.div`
    font-size: 1.15em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(styledprops) =>
      props.breadcrumb
        ? styledprops.theme.color.gray800
        : styledprops.theme.color.gray50};
    p {
      text-transform: uppercase;
      margin: 0;
      line-height: 1em;
      letter-spacing: 0.4em;
      // margin-top: 0.2em;
      cursor: pointer;
    }
    svg {
      fill: currentColor;
      height: 1.5em !important;
      margin-right: 1em;
    }
  `;
  return (
    <Header className={props.className ?? ''}>
      <Heading>
        <Link
          href={props.titleLink ?? '#'}
          passHref={true}
          shallow
          legacyBehavior
        >
          <p style={{ cursor: 'pointer' }}>{props.icon}</p>
        </Link>
        <Link
          href={props.titleLink ?? '#'}
          passHref={true}
          shallow
          legacyBehavior
        >
          <p>{props.title}</p>
        </Link>
        {props.breadcrumb && (
          <Breadcrumb>
            <ArrowRight />
            <BreadcrumbIcon>{props.breadcrumb?.icon}</BreadcrumbIcon>
            <p> {props.breadcrumb?.title}</p>
          </Breadcrumb>
        )}
      </Heading>
      <SideHeading>{props.buttons?.map((button) => button)}</SideHeading>
      {/* <div className="view-switcher">
          <button
            onClick={() => setCurrentView('gallery')}
            className={`view-btn ${currentView === 'gallery' ? 'active' : ''}`}
          >
            <IoImageOutline /> <span>Gallery View</span>
          </button>
          <StyledDivider type="vertical" />
          <button
            onClick={() => setCurrentView('list')}
            className={`view-btn ${currentView === 'list' ? 'active' : ''}`}
          >
            <FiList /> <span>List View</span>
          </button>
        </div> */}
    </Header>
  );
};

export default PageHeader;
